.btn {
    transform: translate(0%, 0%);
    position: relative;
    display: inline-block;
    font-family: 'Inter';
    font-size: 1rem;
    font-weight: 500;
    height: 10%;
    line-height: 10%;
    cursor: pointer;
    background-color: inherit;
    border: none;
    color: #002936;
    padding: 1.156vw 1.5vw;
    border-bottom: 1px solid #097191;
}

.btn span {
    padding-right: 1.2rem;
}

.btn svg {
    vertical-align: middle;
    margin-left: 0.313rem;
}

.btn:hover .background {
    height: #006b92;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -1000%);
}

.btn .background {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 100%;
    background-color: #006b92;
    z-index: -1;
    border-radius: 5px;
    transition: all 0.3s;
}

@media (max-width: 1024px) {
    .btn {
        padding: 0.85rem 1.1rem;
        font-size: 0.875rem;
        height: 10%;
        line-height: 10%;
        border-bottom: 1px solid #097191;
    }

}

@media (max-width: 768px) {
    .btn {
        padding: 0.5rem 0.5%;
        font-size: 0.75rem;
        height: 10%;
        line-height: 10%;
        border-bottom: 1px solid #097191;
        width: 30%;
        margin: 0 auto 0.8rem;
    }
}

@media (max-width: 425px) {
    .btn {
        padding: 0.7rem 0.2rem;
        font-size: 100%;
        border-bottom: 1px solid #097191;
        width: 40%;
        margin: 0 auto 0.5rem;
    }

    .btn span {
        padding: 0;
    }

    svg {
        display: none;
    }

    .btn:hover .background {
        height: #006b92;
        width: 90%;
        top: 100%;
        transform: translate(-50%, -1000%);
    }
}