.slideshow-container {
    position: relative;
    max-width: 100%;
    margin: 4rem 0;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
}

.slides-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8vw;
    padding:1.25rem 0;
}

.prev-btn,
.next-btn {
    border: none;
    cursor: pointer;
    outline: none;
}

.slide-text {
    padding: 1.25rem 5rem;
    max-width: 31.25rem;
}

.slide-author {
    text-align: center;
}

@media (max-width: 650px) {
    .slides-content {
        width: 100%;
        margin: 1rem auto;
    }

    .slide-text {
        padding: 1.25rem 1rem;
    }

    .author-name {
        margin-top: 1rem;
    }

    .prev-btn,
    .next-btn {
        display: block;
        background-color: #303138;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 1.25rem;
    }

    .img-responsive {
        margin: 0 auto;
        padding: 1.25rem;
    }
}
